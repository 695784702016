<template>
  <div class="flex flex-col gap-y-2">
    <sidebar-nav-link
      :to="{name: 'home'}"
      icon="home"
    >
      Accueil et Statistiques
    </sidebar-nav-link>

    <sidebar-nav-link
      v-if="isInDevMode"
      :to="{name: 'test'}"
    >
      Test Page
    </sidebar-nav-link>

    <sidebar-dropdown-menu icon="users" label="Membres">
      <sidebar-nav-link-in-drop-down :to="{name: 'members.list'}">
        Membres
      </sidebar-nav-link-in-drop-down>

      <sidebar-nav-link-in-drop-down :to="{name: 'members.notifications.send'}">
        Envoyer un message
      </sidebar-nav-link-in-drop-down>

      <sidebar-nav-link-in-drop-down :to="{name: 'member-holidays.list'}">
        Congés
      </sidebar-nav-link-in-drop-down>

      <div class="h-px w-full bg-gray-700 my-2"/>

      <sidebar-nav-link-in-drop-down :to="{name: 'stats.new-customers'}">
        Graphique nouveaux clients
      </sidebar-nav-link-in-drop-down>
    </sidebar-dropdown-menu>

    <sidebar-dropdown-menu icon="euro-sign" label="Vente">
      <sidebar-nav-link-in-drop-down
        :to="{name: 'orders.list.today', query: {
        filter_timeSlot: this.$date().startOf('day').format('DD.MM.YYYY HH:mm:ss') + ',' + this.$date().endOf('day').format('DD.MM.YYYY HH:mm:ss'),
      }}"
        execute-before
        @before="$forceUpdate()">
        Commandes aujourd'hui
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down
        :to="{name: 'orders.list.tomorrow', query: {
         filter_timeSlot: this.$date().startOf('day').add(1, 'day').format('DD.MM.YYYY HH:mm:ss') + ',' + this.$date().endOf('day').add(1, 'day').format('DD.MM.YYYY HH:mm:ss'),
      }}"
        execute-before
        @before="$forceUpdate()">
        Commandes demain
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down
        :to="{name: 'orders.list'}">
        Commandes
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'carts.list'}">
        Paniers d'achat
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'invoices.list'}">
        Factures
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'fees.list'}">
        Frais
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'campaigns.list'}">
        Campagnes
      </sidebar-nav-link-in-drop-down>

      <div class="h-px w-full bg-gray-700 my-2"/>

      <sidebar-nav-link-in-drop-down :to="{name: 'unfulfilled-items.list'}">
        Produits manquants
      </sidebar-nav-link-in-drop-down>

      <div class="h-px w-full bg-gray-700 my-2"/>

      <sidebar-nav-link-in-drop-down :to="{name: 'stats.global'}">
        Graphique Ventes Globales
      </sidebar-nav-link-in-drop-down>

      <sidebar-nav-link-in-drop-down :to="{name: 'stats.product-sales'}">
        Statistiques ventes
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'stats.supplier-sales'}">
        Statistiques fournisseurs
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'stats.campaign-sales'}">
        Statistiques campaigns
      </sidebar-nav-link-in-drop-down>
    </sidebar-dropdown-menu>

    <sidebar-dropdown-menu icon="boxes" label="Abonnements">
        <sidebar-nav-link-in-drop-down
                :to="{name: 'subscriptions.upcoming.deliveries', query: {
                    filter_timeslot: this.$date().startOf('day').format('DD.MM.YYYY HH:mm:ss') + ',' + this.$date().endOf('day').add(1, 'month').format('DD.MM.YYYY HH:mm:ss'),
                  }}"
                execute-before
                @before="$forceUpdate()">
            Livraisons à venir
        </sidebar-nav-link-in-drop-down>
        <sidebar-nav-link-in-drop-down :to="{name: 'subscriptions.list'}">
          Abonnements
        </sidebar-nav-link-in-drop-down>
        <sidebar-nav-link-in-drop-down :to="{name: 'subscriptions.skipped-dates'}">
            Dates sautées
        </sidebar-nav-link-in-drop-down>
    </sidebar-dropdown-menu>
    <sidebar-dropdown-menu icon="boxes" label="Produits">
      <sidebar-nav-link-in-drop-down :to="{name: 'products.list'}">
        Produits
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'brands.list'}">
        Marques
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'product-categories.list'}">
        Catégories
      </sidebar-nav-link-in-drop-down>

      <sidebar-nav-link-in-drop-down :to="{name: 'product-categories-virtual.list'}">
        Catégories Virtuel
      </sidebar-nav-link-in-drop-down>

      <sidebar-nav-link-in-drop-down :to="{name: 'stockHistory.list'}">
        Historique Stock
      </sidebar-nav-link-in-drop-down>

      <div class="h-px w-full bg-gray-700 my-2"/>

      <sidebar-nav-link-in-drop-down :to="{name: 'products.reports.valorlux.list'}">
        Rapport Valorlux
      </sidebar-nav-link-in-drop-down>

      <sidebar-nav-link-in-drop-down :to="{name: 'products.reports.statec.list'}">
        Rapport Statec
      </sidebar-nav-link-in-drop-down>
    </sidebar-dropdown-menu>

    <sidebar-dropdown-menu icon="truck" label="Livraison">
      <sidebar-nav-link-in-drop-down :to="{name: 'route-manager.index'}">
        Routes
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down
        :to="{name: 'deliveries.list.today', query: {
        filter_timeslot: this.$date().startOf('day').format('DD.MM.YYYY HH:mm:ss') + ',' + this.$date().endOf('day').format('DD.MM.YYYY HH:mm:ss'),
      }}"
        execute-before
        @before="$forceUpdate()">
        Livraisons aujourd'hui
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down
        :to="{name: 'deliveries.list.tomorrow', query: {
        filter_timeslot: this.$date().startOf('day').add(1, 'day').format('DD.MM.YYYY HH:mm:ss') + ',' + this.$date().endOf('day').add(1, 'day').format('DD.MM.YYYY HH:mm:ss'),
      }}"
        execute-before
        @before="$forceUpdate()">
        Livraisons demain
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down
        :execute-before="true"
        :to="{name: 'deliveries.list'}"
        @before="$store.commit('listTable/filters/resetFilterValues', 'deliveriesTable')"
      >
        Livraisons
      </sidebar-nav-link-in-drop-down>

      <sidebar-nav-link-in-drop-down :to="{name: 'deliveryTimeSlots.list'}">
        Créneaux de livraison
      </sidebar-nav-link-in-drop-down>

      <sidebar-nav-link-in-drop-down :to="{name: 'postalCodes.list'}">
        Codes Postaux
      </sidebar-nav-link-in-drop-down>


      <div class="h-px w-full bg-gray-700 my-2"/>


      <sidebar-nav-link-in-drop-down :to="{name: 'vehicles.list'}">
        Véhicules
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'devices.list'}">
        Terminaux
      </sidebar-nav-link-in-drop-down>
    </sidebar-dropdown-menu>

    <sidebar-dropdown-menu icon="home" label="Achat">
      <sidebar-nav-link-in-drop-down :to="{name: 'products.missing-stock'}">
        Défauts de stock
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'supplier-orders.list'}">
        Commandes
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'supplier.catalog'}">
        Catalogues fournisseur
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'supplier.catalog.count-list'}">
        Import catalogue
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'supplier.catalog.price-comparison'}">
        Comparateur de prix
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'arrival-temperatures.list'}">
        Températures d'arrivage
      </sidebar-nav-link-in-drop-down>

    </sidebar-dropdown-menu>

    <sidebar-dropdown-menu icon="desktop" label="Shop">
      <sidebar-nav-link-in-drop-down :to="{name: 'blog.list'}">
        Blog & Recettes
      </sidebar-nav-link-in-drop-down>

      <sidebar-nav-link-in-drop-down :to="{name: 'blog.categories.list'}">
        Catégories Blog
      </sidebar-nav-link-in-drop-down>

      <sidebar-nav-link-in-drop-down :to="{name: 'banners.list'}">
        Banners
      </sidebar-nav-link-in-drop-down>

      <sidebar-nav-link-in-drop-down :to="{name: 'shortUrls.list'}">
        ShortUrls
      </sidebar-nav-link-in-drop-down>
    </sidebar-dropdown-menu>

    <sidebar-dropdown-menu icon="receipt" label="Rapports">
      <sidebar-nav-link-in-drop-down :to="{name: 'reportQueries.list'}">
        Gérer rapports
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'reportQueries.execute'}">
        Exécuter un rapport
      </sidebar-nav-link-in-drop-down>
    </sidebar-dropdown-menu>

    <sidebar-dropdown-menu icon="database" label="Gestion">
      <sidebar-nav-link-in-drop-down :to="{name: 'tags.list'}">
        Tags
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'departments.list'}">
        Departements
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'deposits.list'}">
        Vidange
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'racks.list'}">
        Étagères
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'logs.list'}">
        Logs
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'roles.list'}">
        Rôles
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'fulfilmentlabel.print.index'}">
        Imprimer étiquette fulfilment
      </sidebar-nav-link-in-drop-down>
    </sidebar-dropdown-menu>


    <sidebar-dropdown-menu icon="user-tie" label="Personnel">
      <sidebar-nav-link-in-drop-down :to="{name: 'task.list'}">
        Tâches
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'taskRecurring.list'}">
        Tâches récurrentes
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'timeclock.list'}">
        Pointages
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'totalTimeclock.list'}">
        Pointages Total
      </sidebar-nav-link-in-drop-down>
    </sidebar-dropdown-menu>

    <sidebar-dropdown-menu icon="chart-line" label="Comptabilité">
      <sidebar-nav-link-in-drop-down :to="{name: 'booking-accounts.list'}">
        Comptes Booking
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'accounting.bankAccounts.list'}">
        Comptes bancaires
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'accounting.bankMovements.list'}">
        Mouvements bancaires
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'accounting.invoices.list'}">
        Factures
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'accounting.external-invoice-pdfs.list'}">
        PDFs factures à traiter
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'accounting.payments.list'}">
        Payments
      </sidebar-nav-link-in-drop-down>
      <sidebar-nav-link-in-drop-down :to="{name: 'accounting.paymentsLog.list'}">
        Payments Log
      </sidebar-nav-link-in-drop-down>
    </sidebar-dropdown-menu>
  </div>
</template>

<script>
import SidebarDropdownMenu from "@/components/layouts/dashboard/partials/sidebar/SidebarDropdownMenu";
import SidebarNavLinkInDropDown from "@/components/layouts/dashboard/partials/sidebar/SidebarNavLinkInDropDown";
import SidebarNavLink from "@/components/layouts/dashboard/partials/sidebar/SidebarNavLink.vue";

export default {
  name: "SidebarLinks",
  components: {SidebarNavLink, SidebarNavLinkInDropDown, SidebarDropdownMenu},
  computed: {
    isInDevMode() {
      return process.env.NODE_ENV === 'development';
    }
  },

}
</script>

<style scoped>

</style>